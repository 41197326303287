/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { type CommonFieldError, type Errors, type IAppError, toErrorObject } from '@/helpers/errors'
import { isObject } from 'lodash'
import type { FieldValues, Path } from 'react-hook-form'
export function parseFieldValue(value: Blob | string) {
  if (value instanceof Blob) {
    return value
  }
  try {
    return JSON.parse(value)
  } catch {
    return value
  }
}

export function INTERNAL_setValue(values: Record<string, any>, key: string, value: any) {
  if (value === '[object Object]') {
    values[key] = undefined
    return
  }
  if (Array.isArray(values[key])) {
    const valueArray = values[key]
    if (valueArray.includes(value)) return
    valueArray.push(value)
    return
  }
  if (values[key]) {
    // Intentionally using the == operator as we will have couplets like "1" and 1
    if (values[key] == value) return
    values[key] = [values[key], value]
    return
  }
  values[key] = value
}

export function fromFormDataFlattened<T extends FieldValues>(formData: FormData | undefined) {
  if (!formData) {
    return
  }
  const result: Record<any, any> = {}
  // special useFormState input values start with $
  for (const [key, value] of formData) {
    if (key.startsWith('$')) {
      continue
    }
    const parsed = parseFieldValue(value)
    INTERNAL_setValue(result, key, parsed)
  }
  return result as Record<Path<T>, any>
}

export function fromFormData<T extends FieldValues>(formData: FormData | undefined) {
  if (!formData) {
    return
  }
  const result: Record<any, any> = {}
  const entries = [...formData.entries()].sort(([a], [b]) => (a > b && 1) || (b > a && -1) || 0)
  for (const [key, value] of entries) {
    // special useFormState input values start with $
    if (key.startsWith('$')) {
      continue
    }
    const parsed = parseFieldValue(value)
    if (key.includes('.')) {
      const path = key.split('.')
      let current = result
      for (let depth = 0; depth < path.length; depth++) {
        const part = path[depth]!
        if (depth === path.length - 1) {
          INTERNAL_setValue(current, part, parsed)
          continue
        }
        const isArray = parseInt(path[depth + 1]!, 10) === 0
        current[part] = current[part] || (isArray ? [] : {})
        current = current[part]
      }
      continue
    }
    INTERNAL_setValue(result, key, parsed)
  }
  return result as T
}

export function toFormData<T extends FieldValues>(data: T): FormData {
  const formData = new FormData()
  const path: string[] = []
  function inner(key: string | null, value: any) {
    const formDataKey = (key ? path.concat(key) : path).join('.')
    if (typeof value === 'string' || value instanceof Blob) {
      formData.append(formDataKey, value)
    }
    if (Array.isArray(value)) {
      for (const v of value) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        formData.append(formDataKey, parseFieldValue(v))
      }
    }
    if (isObject(value)) {
      if (key) {
        path.push(key)
      }
      for (const [k, v] of Object.entries(value)) {
        inner(k, v)
      }
      path.pop()
      return
    }
    return formData.append(formDataKey, JSON.stringify(value))
  }
  inner(null, data)
  return formData
}

export function errorToFieldErrors<T extends FieldValues>(
  error: IAppError | undefined,
  defaultMessage: string
): Errors<
  T & {
    root: unknown
  }
> {
  if (!error) {
    return {} as Errors<T & { root: unknown }>
  }
  const convertedMessages = error.errors.map((error) => {
    let message = defaultMessage
    if ('message' in error && error.message) {
      message = error.message
    }
    return {
      ...error,
      message,
      type: 'FieldError',
      path: 'path' in error ? error.path : ['root'],
    } as CommonFieldError
  })
  return toErrorObject<T & { root: unknown }>(convertedMessages)
}
