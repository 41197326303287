import { type FieldPath, type FieldPathValues, type FieldValues, useFormContext, useWatch } from 'react-hook-form'

function useActionFormValues<T extends FieldValues, TPaths extends Array<FieldPath<T>>>(
  names: [...TPaths]
): [...FieldPathValues<T, [...TPaths]>] {
  const { getValues } = useFormContext()
  useWatch({ name: names })
  return getValues(names)
}

export function useAllActionFormValues<T extends FieldValues>() {
  const { getValues } = useFormContext<T>()
  useWatch()
  return getValues()
}

export function withValues<TFormValues extends FieldValues>() {
  return useActionFormValues as <TPaths extends Array<FieldPath<TFormValues>>>(
    names: [...TPaths]
  ) => [...FieldPathValues<TFormValues, [...TPaths]>]
}
export default useActionFormValues
