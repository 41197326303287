import { isArray, keysOf } from '@betterplace/utils'
import type { Params, QueryParams } from '@/types'

type AllParams<TPath extends string, TTypeMap extends Record<string, unknown> = {}> = Params<TPath, TTypeMap> &
  QueryParams<TPath>
type PathFactories<TDefinition extends Record<string, string>> = {
  [TKey in keyof TDefinition]: (params: AllParams<TDefinition[TKey]>) => string
}

function addQueryParam(path: string, key: string, value: string | string[], first: boolean) {
  if (isArray(value)) {
    let result = ''
    for (let i = 0; i < value.length; i++) {
      const v = value[i]!
      result = addQueryParam(result, key, v, first && i === 0)
    }
    return `${path}${result}`
  }
  if (first) {
    return `${key}=${value}`
  }
  return `${path}&${key}=${value}`
}

function toPathFactories<TDefinition extends Record<string, string>>(
  definition: TDefinition,
  getBaseUrl?: () => string
) {
  const result = {} as PathFactories<TDefinition>
  for (const key in definition) {
    const template = definition[key]!
    result[key] = toPathFactory(template, getBaseUrl)
  }
  return result
}

function paramValueToString(paramValue: unknown): string {
  const value = Array.isArray(paramValue)
    ? paramValue.filter((v) => v !== null && v !== undefined).join('/')
    : paramValue === undefined || paramValue === null
      ? ''
      : String(paramValue)
  return value
}

export function toPathFactory<TPath extends string, TTypeMap extends Record<string, unknown> = {}>(
  template: TPath,
  getBaseUrl?: () => string
): (params: AllParams<TPath, TTypeMap>, pathNameOnly?: boolean) => string {
  const [pathname] = template.split('?') as [string, string?]
  return (params, pathNameOnly?: boolean) => {
    let path = pathname.slice()
    const paramKeys = keysOf(params)
    const found = new Set<string>()
    for (const key of paramKeys) {
      let templateKey = `[${String(key)}]`
      if (!path.includes(templateKey)) {
        templateKey = `[...${String(key)}]`
        if (!path.includes(templateKey)) continue
      }
      found.add(String(key))
      const paramValue = params[key]
      path = path.replace(templateKey, paramValueToString(paramValue))
    }
    let query = ''
    let count = 0
    for (let i = 0; i < paramKeys.length; i++) {
      const key = paramKeys[i]!
      if (found.has(String(key))) {
        continue
      }
      const value = params[key]!
      query = addQueryParam(query, String(key), value, count === 0)
      count++
    }
    const pathName = `${path}${query ? `?${query}` : ''}`
    if (pathNameOnly) {
      return pathName
    }
    return `${getBaseUrl?.() ?? process.env.NEXT_PUBLIC_BETTERPLACE_URL}${pathName}`
  }
}

export default toPathFactories
