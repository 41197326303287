/* eslint-disable import/no-unused-modules */
/* eslint-disable import/prefer-default-export */
import { CommonError } from './types'
import { ErrorTypes } from './ErrorTypes'
import { ZodError, ZodIssue } from 'zod'
import { isArray } from '@betterplace/utils'
import type { FieldError } from '@betterplace/api-graphql-types'
import type { GraphQLFormattedError } from 'graphql'

import { AppError, IAppError } from './'
import { Translator } from '@/i18n/types'
import type { ApolloError } from '@apollo/client'
import type { FieldErrors } from 'react-hook-form'
const BASE_PATH = 'base'

export type ServerPathMapper = (serverPath: string) => string[]

export function fromZodIssue<T = unknown>(issue: ZodIssue): CommonError<T> {
  const path = isArray(issue.path) ? issue.path : [issue.path]
  if (path[0] === 'root') {
    return {
      message: issue.message,
      type: ErrorTypes.FormError,
    }
  }
  return {
    path,
    message: issue.message,
    type: ErrorTypes.FieldError,
    errorType: issue.code as T,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fromError<T = unknown>(error: any, t: Translator<'nextjs'>): CommonError<T> {
  if (isUnauthorizedError(error) || (isApolloError(error) && isUnauthorizedError(error.networkError))) {
    return {
      type: ErrorTypes.UnauthorizedError,
      message: t('errors.messages.unauthorized'),
    }
  }
  if ('message' in error) {
    return {
      message: t('errors.messages.general'),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      original_message: error.message,
      type: ErrorTypes.GeneralError,
    }
  }
  return {
    type: ErrorTypes.UnknownError,
  }
}
export function fromGraphQLError<T = unknown>(error: GraphQLFormattedError, t: Translator<'nextjs'>): CommonError<T> {
  return {
    type: ErrorTypes.GeneralError,
    message: t('errors.messages.general'),
    original_message: error.message,
  }
}

export function fromFieldError<T = unknown>(error: FieldError, mapPaths?: ServerPathMapper): CommonError<T> {
  if (error.field === BASE_PATH) {
    return {
      type: ErrorTypes.FormError,
      message: error.message,
    }
  }
  const paths = mapPaths?.(error.field) ?? [error.field]
  return {
    type: ErrorTypes.FieldError,
    path: paths,
    message: error.message,
    errorType: (error.errorType as T) ?? undefined,
  }
}

export function toErrorObject<T extends Record<string | number, unknown>, V = unknown>(
  errors: Array<CommonError<V>>
): FieldErrors<T> {
  function getErrorPath(error: CommonError<V>) {
    return 'path' in error ? error.path : ['root']
  }
  function inner(errors: Array<CommonError<V>>, depth = 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorObject: Record<any, any> = {}
    for (const error of errors) {
      if (!('message' in error)) {
        continue
      }
      const path = getErrorPath(error)
      const field = path[depth]!
      const lastLevel = path.length - 1 - depth < 1
      const currentPath = path.slice(0, depth + 1).join('.')
      if (!lastLevel) {
        const nestedErrors = errors.filter((err) => getErrorPath(err).join('.').startsWith(currentPath))
        errorObject[field] = inner(nestedErrors, depth + 1)
        continue
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      errorObject[field] = errorObject[field] ?? {
        message: error.message,
        type: 'errorType' in error ? error.errorType : undefined,
      }
    }
    return errorObject
  }
  return inner(errors) as FieldErrors<T>
}

export function fromErrorObject<V = unknown>(
  errors: Record<string, unknown>,
  parentPath: string[] = []
): Array<CommonError<V>> {
  const result: Array<CommonError<unknown>> = []

  for (const [key, value] of Object.entries(errors)) {
    const currentPath = [...parentPath, key]

    if (value && typeof value === 'object' && 'message' in value && typeof value.message === 'string') {
      // This is a leaf node with an error
      const generalError = !key || key === 'root'
      if (generalError) {
        result.push({
          type: ErrorTypes.GeneralError,
          message: value.message,
        })
        continue
      }
      const error: CommonError = {
        type: ErrorTypes.FieldError,
        path: currentPath,
        message: value.message,
      }
      if ('type' in value) {
        error['errorType'] = value.type
      }
      result.push(error)
    } else if (value && typeof value === 'object') {
      // This is a nested error object
      result.push(...fromErrorObject(value as Record<string, unknown>, currentPath))
    }
  }

  return result as Array<CommonError<V>>
}

export function isApolloError(err: unknown): err is ApolloError {
  return !!err && typeof err === 'object' && 'message' in err && 'graphQLErrors' in err
}

export function isNetworkError(err: unknown): err is { statusCode: number } {
  return err instanceof Error && 'statusCode' in err && typeof err.statusCode === 'number'
}

export function isRedirectError(err: unknown): err is { message: 'NEXT_REDIRECT' } {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  return !!err && (err as any)?.message === 'NEXT_REDIRECT'
}

export function isNotFoundError(err: unknown): err is { message: 'NEXT_NOT_FOUND' } {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  return !!err && (err as any)?.message === 'NEXT_NOT_FOUND'
}

export function isUnauthenticatedError(err: unknown): err is { statusCode: 401 } {
  return isNetworkError(err) && err.statusCode === 401
}

export function isUnauthorizedError(err: unknown): err is { statusCode: 403 } {
  return isNetworkError(err) && err.statusCode === 403
}

export function isZodError(err: unknown): err is ZodError {
  return err instanceof ZodError
}

export function isAppError(err: unknown): err is AppError {
  return err instanceof AppError
}

export function isIAppError(err: unknown): err is IAppError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  return (err as any)?.__typename === 'AppError'
}
